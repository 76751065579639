import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as styles from "../css/phoneVideo.module.css"

const PhoneVideo = (props) => (
    <div className={`${styles.videoContainer} ${props.className}`}>
        <video autoPlay loop muted playsInline className={styles.video} >
            <source src={props.mp4} />
            <source src={props.webm} />
        </video>
        <div className={styles.overlay} >
            <StaticImage src="../images/iphone-frame.png" alt="itineraryApp" />
        </div>
    </div>
)

export default PhoneVideo
