import * as React from "react"

import * as styles from "../css/tombstone.module.css"

const Tombstone = (props) => {
  return(
    <div className={styles.tombstone}>
        <div className={styles.grouping}>
            <h4 className={styles.header}>{props.header}</h4>
            <h5 className={styles.sub}>{props.sub}</h5>
            <p className={styles.text}>{props.text}</p>
        </div>
    </div>
)}

export default Tombstone
