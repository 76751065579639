// extracted by mini-css-extract-plugin
export var carousel = "servicesWidget-module--carousel--c88ef";
export var carouselBtn = "servicesWidget-module--carouselBtn--52605";
export var carouselIndicator = "servicesWidget-module--carouselIndicator--9249c";
export var carouselItem = "servicesWidget-module--carouselItem--d73d2";
export var container = "servicesWidget-module--container--e72c0";
export var header = "servicesWidget-module--header--aa24f";
export var image = "servicesWidget-module--image--8ce11";
export var info = "servicesWidget-module--info--12237";
export var infoContainer = "servicesWidget-module--infoContainer--6d00c";
export var infoText = "servicesWidget-module--infoText--b595d";
export var sub = "servicesWidget-module--sub--f218d";