import React from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useViewport } from "../contexts/viewportProvider";

import * as styles from "../css/servicesWidget.module.css"
import { StaticImage } from "gatsby-plugin-image";


const ServicesWidget = () => {
    const { width } = useViewport();
    const breakpoint = 700;

    return (
        <div className={styles.container}>
            <h2 className={`${styles.header} subheader`}>Our Services</h2>
            <Carousel
                className={styles.carousel}
                controls 
                indicators 
                showThumbs={false}
                infiniteLoop
                showStatus={false}
                showIndicators={width < breakpoint ?? false}
                autoPlay
                interval={10000}
                renderIndicator={() => {
                    return (
                        <StaticImage className={styles.carouselIndicator} src="../images/pyt-carousel-indicator.png" alt="Indicator" />
                    )
                }}
                renderArrowPrev={(clickHandler) => {
                    return (
                        <div onClick={clickHandler} onKeyDown={(k) => k.key === "ArrowLeft" ? clickHandler : null} role="button" tabIndex={0}>
                          <StaticImage className={styles.carouselBtn} src="../images/LeftBtn.png" alt="Previous" />
                        </div>
                    );
                  }}
                renderArrowNext={(clickHandler) => {
                    return (
                        <div onClick={clickHandler} onKeyDown={(k) => k.key === "ArrowRight" ? clickHandler : null} role="button" tabIndex={0}>
                            <StaticImage className={styles.carouselBtn} src="../images/RightBtn.png" alt="Previous" />
                        </div>
                    );
                }}
                >
                    <div className={styles.carouselItem}>
                        <StaticImage className={styles.image} src="../images/infoCards/5.png" alt="" />
                        <div className={styles.infoContainer}>
                            <h3 className={styles.info}>Full Service Itinerary Planning</h3>
                            <p className={styles.infoText}>This is our favorite service we provide! We take the time to get to know your travel dreams, bucket list destinations + activities, relationship dynamics, and more. From there, we take the time to curate a memorable trip for you and your fellow travelers so you don’t have to worry about the stress and details. We will help with finding the right destination, hotels, activities, transfers, dining, and more. Before you leave, you will receive access to an app that houses your personalized itinerary. On top of all of that, we have relationships with different hotels and partners around the world to provide exclusive VIP experiences.</p>
                        </div>
                    </div>
                    <div className={styles.carouselItem}>
                        <StaticImage className={styles.image} src="../images/infoCards/7.png" alt="" />
                        <div className={styles.infoContainer}>
                            <h3 className={styles.info}>Hotel Booking and Expertise</h3>
                            <p className={styles.infoText}>This service is for the traveler who wants the perks and VIP status but doesn’t need assistance with personalized activities, private transfers, and more. <br />You will provide us with the destination, accommodation budget, and dates, and we will send you hotel recommendations based on your preferences. You will still have access to our partnership hotels were you are VIP’d (all around the world!) at over 1,400 worldwide.</p>
                        </div>
                    </div>
                    <div className={styles.carouselItem}>
                        <StaticImage className={styles.image} src="../images/infoCards/3.png" alt="" />
                        <div className={styles.infoContainer}>
                            <h3 className={styles.info}>Direct Hotel Booking</h3>
                            <h5 className={styles.sub}>complimentary</h5>
                            <p className={styles.infoText}>This service is for the traveler who knows exactly where they want to go and where they want to stay, they just wants us to add those special perks you can only get by booking through us: 
                            <br />breakfast daily for 2 
                            <br />$100 credit 
                            <br />VIP status 
                            <br />upgrades* 
                            <br />early check in* 
                            <br />late check out*</p>
                        </div>
                    </div>
                    <div className={styles.carouselItem}>
                        <StaticImage className={styles.image} src="../images/infoCards/8.png" alt="" />
                        <div className={styles.infoContainer}>
                            <h3 className={styles.info}>Disney Travel Planning</h3>
                            <p className={styles.infoText}>Creating magical moments for the whole family is what we do best. With our Disney travel services, you can leave the planning to us and focus on making cherished memories. From enchanting theme park adventures to comfortable accommodations, we've got your family's dream Disney vacation covered. Let the magic begin!</p>
                        </div>
                    </div>
                    <div className={styles.carouselItem}>
                        <StaticImage className={styles.image} src="../images/infoCards/6.png" alt="" />
                        <div className={styles.infoContainer}>
                            <h3 className={styles.info}>Flight Assistance Only</h3>
                            <p className={styles.infoText}>Unlock the world of stress free travel with our flight ticketing service. By booking through us, you not only have access to a large variety of flight options, but there is an increased chance to secure better rates than those found online for premium cabin classes. We work with a dedicated air team to help ensure your travel is as smooth as possible.</p>
                        </div>
                    </div>
            </Carousel>
        </div>
    );
  }

export default ServicesWidget