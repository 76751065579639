import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Tombstone from "../components/tombstone"
import PytPlane from "../components/pytPlane"
import mp4 from "../assets/video/phoneVideo.mp4"
import webm from "../assets/video/phoneVideo.webm"
import PhoneVideo from "../components/phoneVideo"
import Cta from "../components/cta"
import { StaticImage } from "gatsby-plugin-image"
import IgWidget from "../components/igWidget"
import ServicesWidget from "../components/servicesWidget"

import * as styles from "../css/services.module.css"

const ServicesPage = () => (
  <Layout>
    <Seo title="Perfectly Yours Travel Co. Services Page" />
    <div className={styles.workingWithUsOuterContainer}>
      <div className={styles.workingWithUsInnerContainer}>
        <div className={styles.workingWithUsContainer}>
          <h3 className={styles.workingWithUs}>Working With Us</h3>
          <h4 className={styles.subheading}>PYT Co's Planning Process</h4>
          <div className={styles.tombstones}>
            <Tombstone header="01"
                      sub="YOU ENVISION"
                      text="after dreaming of your epic trip, you submit an inquiry form through our website to fill us in on your wanderlust."
            />
            <Tombstone header="02"
                      sub="WE CURATE"
                      text="after getting to know more about you and your travel preferences, we put together a proposal based on your style + budget."
            />
            <Tombstone header="03"
                      sub="COLLABORATION"
                      text="we go back and forth until the perfect itinerary has been matched to you + your travel companions"
            />
            <Tombstone header="04"
                      sub="WE CONFIRM"
                      text="our team works behind the scenes to book all the fun stuff, including dining, reservations, transportation, spa appointments, + more!"
            />
            <Tombstone header="05"
                      sub="YOU EXPLORE"
                      text="you take off + enjoy your trip. we are available for you while you are traveling, along with our in-country partners. be sure to tag us so we can follow along"
            />
          </div>
          <h5 className={styles.instagramHandle}>@perfectlyourstravelco</h5>
        </div>
        <div className={styles.overlay}>
          <div  className={styles.plane01}>
            <PytPlane />
          </div>
          <div  className={styles.plane02}>
            <PytPlane delay={1} />
          </div>
          <div  className={styles.plane03}>
            <PytPlane delay={2} />
          </div>
          <div  className={styles.plane04}>
            <PytPlane delay={3} />
          </div>
        </div>
      </div>
    </div>
    <div className={styles.servicesSelectionContainer}>
      {/* <InfoDisplayCard  header="fully customized itinerary planning"
                        text="This is our favorite service we provide! We take the time to get to know your travel dreams, bucket list destinations + activities, relationship dynamics, and more. From there, we take the time to curate a memorable trip for you and your travelers so you don’t have to worry about the stress and details. We will help with finding the right destination, hotels, activities, transfers, dining, and more. Before you leave, you will receive access to an app that houses your whole itinerary. On top of all of that, we have relationships with different hotels and partners around the world to provide that VIP experience."
       /> */}
       <ServicesWidget />
    </div>
    <Cta />
    <div className={styles.itineraryAppContainer}>
      <div className={styles.itineraryApp} >
        <div className={styles.videoContainer}>
          <PhoneVideo className={styles.video} webm={webm} mp4={mp4} />
        </div>
        <div className={styles.itineraryDesc}>
          <h3 className={styles.workingWithUs}>Itinerary App</h3>
          <p className={styles.textCenter}>One of our traveler's favorite perks of working with us is the app that houses all their trip details. Clients are able to see a day by day snapshot of their trip and find all the necessary details they need such as:</p>
          <ul className={styles.list}>
            <li className={styles.listItem}>confirmation numbers</li>
            <li className={styles.listItem}>flights (that alerts you if your flight gets delayed!)</li>
            <li className={styles.listItem}>messaging with your travel planner</li>
            <li className={styles.listItem}>accommodations</li>
            <li className={styles.listItem}>private transfer details</li>
            <li className={styles.listItem}>rental car information</li>
            <li className={styles.listItem}>dining reservations</li>
            <li className={styles.listItem}>private or shared excursions</li>
            <li className={styles.listItem}>city guides</li>
            <li className={styles.listItem}>tipping guidelines</li>
            <li className={styles.listItem}>+ more!</li>
          </ul>
        </div>
      </div>
    </div>
    <div className={styles.ourForteContainer}>
      <h3 className={styles.ourForte}>Our Forte</h3>
      <div className={styles.ourForteImagesContainer}>
        <div className={styles.ourForteSet}>
          <h5 className={styles.ourForteText}>Honeymoon Getaways</h5>
          <StaticImage className={styles.ourForteImage} src="../images/photos/Positano.png" alt="" />
        </div>
        <div className={styles.ourForteSet}>
          <h5 className={styles.ourForteText}>Destination Weddings</h5>
          <StaticImage className={styles.ourForteImage} src="../images/photos/Paul+Val.png" alt="" />
          <p className={styles.imgCredit}>Lima Conlon Photography</p>
        </div>
        <div className={styles.ourForteSet}>
          <h5 className={styles.ourForteText}>Family Vacations</h5>
          <StaticImage className={styles.ourForteImage} src="../images/photos/Cliffs.png" alt="" />
        </div>
        <div className={styles.ourForteSet}>
          <h5 className={styles.ourForteText}>Bucket List Adventures</h5>
          <StaticImage className={styles.ourForteImage} src="../images/photos/Elephant.png" alt="" />
        </div>
      </div>
    </div>
    <IgWidget />
  </Layout>
)

export default ServicesPage
