import * as React from "react"

import * as styles from "../css/pytPlane.module.css"

const PytPlane = ( props ) => {
    const delay = "delay" + props.delay;

    return (
    <svg
        width="103.12582"
        height="52.873871"
        viewBox="0 0 103.12582 52.873871"
        fill="none"
        version="1.1"
        className={`${styles[delay]} ${styles.pytPlane}`}
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="m 1.0000087,38.255942 c 26.5272903,21.43024 56.9534403,12.58243 65.3616103,6.79117"
            stroke="#e3a693"
            strokeWidth="2"
            strokeLinecap="round"
            id="path144" />
        <path
            d="m 72.528119,44.047112 c 1.739,-3.494 4.92,-10.855 3.727,-12.352 -1.491,-1.872 -10.062,-1.872 -7.081,0.374 2.981,2.246 4.845,2.62 5.963,3.743 1.118,1.123 4.099,5.615 4.845,4.866 0.745,-0.748 1.491,-1.497 1.491,-2.246 0,-0.748 -2.237,-10.481 -1.864,-11.229 0.373,-0.749 3.354,-6.738 2.609,-7.112 -0.745,-0.375 -5.218,-1.498 -7.454,-2.246 -2.236,-0.749 -8.944,-3.369 -7.081,-3.743 1.864,-0.375 13.044,-1.123 13.417,-1.123 0.373,0 7.081,0 8.199,-1.123 0.894,-0.899 5.342,-6.1139997 7.454,-8.6099997 1.118,-1.123 3.652001,-3.069 4.845001,-1.871 1.192,1.198 0,4.492 -0.746,5.989 -1.366001,2.121 -4.248001,6.6629997 -4.845001,7.8609997 -0.745,1.497 -0.372,8.983 0,10.48 0.373,1.498 4.100001,10.856 2.236,12.353 -1.49,1.198 -4.348,-3.494 -5.59,-5.989 -0.745,-2.121 -2.609,-6.364 -4.099,-6.364 -1.491,0 -3.603,2.496 -4.473,3.744"
            stroke="#e3a693"
            strokeWidth="2"
            strokeLinecap="round"
            id="path146" />
    </svg>
)
}

export default PytPlane
